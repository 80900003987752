@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.inputContainer {
  .label {
    font-family: $fontWorkSans;
    font-size: 14px;
    padding-left: 10px;
    padding-bottom: 4px;

    &.errorLabel {
      color: $colorPrimary;
    }
  }

  .inputWrapper {
    display: flex;
    align-items: center;

    .checkboxContainer {
      display: flex;
      align-items: center;

      .checkbox {
        height: 16px;
        width: 16px;
        margin-right: 10px;
        border: 1px solid $colorText;
        border-radius: 2px;
        box-sizing: border-box;

        &.error {
          border-color: $colorPrimary;
        }
      }

      label {
        width: calc(100% - 16px);
        font-size: 12px;
        line-height: 14px;
        font-family: $fontWorkSans;
        color: $colorPrimary;
      }
    }

    .input, .textarea {
      background-color: white;
      border: 1px solid $colorText;
      color: $colorText;
      box-sizing: border-box;
      outline: none;
      width: 100%;
      height: 46px;
      border-radius: 8px;
      font-size: 16px;
      font-family: $fontWorkSans;
      line-height: 19px;
      padding-left: 10px;
      padding-right: 10px;

      &.error {
        border-color: $colorPrimary;
      }
    }

    .textarea {
      padding: 10px;
    }

    .postfix {
      margin-left: 6px;
      font-family: $fontWorkSans;
      color: $colorText;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .errorText {
    font-size: 12px;
    color: $colorPrimary;
    padding-left: 10px;
    padding-top: 6px;
    font-family: $fontWorkSans;
  }
}
