@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.modalContainer {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $colorBackgroundWhite;
  border-radius: 8px;
  padding-left: 32px;
  padding-right: 32px;
  overflow-y: auto;
  max-height: 100vh;

  .title {
    font-family: $fontMontserrat;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $colorText;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 26px;

    svg {
      position: absolute;
      right: 32px;
      cursor: pointer;
    }
  }

  .footer {
    padding-bottom: 26px;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: $colorBlackOverlay;
  top: 0;
  z-index: 4;
}