@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.navigationContainer {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  z-index: 2;

  &.isDrawerOpen {
    background-color: $colorBackgroundWhite;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;

    @include bp(m) {
      padding-left: 100px;
      padding-right: 100px;
    }

    .logoSection {
      align-items: center;
      display: flex;
    }

    .linksSection {
      display: flex;
      align-items: center;

      a {
        font-family: $fontWorkSans;
        font-weight: 400;
        font-style: normal;
        color: $colorText;
        text-decoration: none;
        font-size: 16px;
        margin-left: 20px;

        @include bp(xl) {
          margin-left: 30px;
        }

        &.isHomepage {
          color: $colorTextSecondary;
        }
      }

      .buttonGroup {
        margin-left: 50px;

        button {
          &:nth-child(1) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:nth-child(2) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      .languageSwitch {
        color: $colorText;
        font-family: $fontWorkSans;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        margin-right: 10px;

        @include bp(m) {
          margin-left: 30px;
          margin-right: 0;
        }


        &.isHomepage {
          color: $colorTextSecondary;
        }

        svg {
          margin-left: 6px;
        }
      }

      .burgerIcon {
        display: flex;
        align-items: center;
        color: $colorText;
        font-size: 18px;
        margin-left: 16px;
        width: 20px;

        &.isHomepage {
          color: $colorTextSecondary;
        }
      }
    }
  }
}

.mobileDrawer {
  top: 100px;
  position: absolute;
  width: 100%;
  background-color: $colorBackgroundWhite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  z-index: 3;

  a {
    font-family: $fontWorkSans;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    color: $colorPrimary;
    text-decoration: none;

    svg {
      margin-left: 10px;
    }
  }

  button {
    margin-top: 10px;
    width: 200px;
  }
}
