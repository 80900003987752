$fontWorkSans: "Work Sans", sans-serif;
$fontMontserrat: 'Montserrat', sans-serif;

$colorPrimary: #CA2A3F;
$colorPrimaryActive: #A22232;
$colorPrimaryDarker: #ab1527;
$colorPrimaryBright: #FF7D7D;
$colorPrimaryBrightest: #fdaaaa;

$colorBackgroundWhite: #FFFFFF;
$colorBackgroundSecondary: #EDEDED;
$colorBackgroundSecondaryDarker: #e0dddd;
$colorBackgroundSelected: #D0D0D0;
$colorBackgroundDisabled: #a8a6a6;

$colorText: #1F1F1F;
$colorTextSecondary: #FFFFFF;
$colorBlack: #000000;
$colorBlackOverlay: rgba(0, 0, 0, 0.6);
$colorGreen: #0B681F;
