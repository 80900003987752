@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.registerContainer {
  width: 260px;

  @include bp(xs) {
    width: 300px;
  }

  @include bp(s) {
    width: 372px;
  }

  @include bp(m) {
    width: 666px;
  }

  .inputColumns {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    @include bp(m) {
      flex-direction: row;
      justify-content: space-between;
    }

    .column {
      &:first-child {
        @include bp(m) {
          padding-right: 32px;
          border-right: 1px solid black;
        }
      }
    }

    .input {
      margin-top: 16px;

      @include bp(m) {
        width: 300px;
      }

      input, select {
        border-radius: 4px;
      }
    }
  }

  .button {
    margin-top: 30px;
  }

  .footer {
    font-family: $fontWorkSans;
    font-size: 14px;
    line-height: 16px;
    color: $colorText;
    text-align: center;
    margin-top: 20px;

    .action {
      margin-left: 6px;
      cursor: pointer;
      color: $colorPrimary;
      text-decoration: underline;
    }
  }
}