@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.alert {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 19px;
  font-size: 12px;
  font-family: $fontMontserrat;
  font-weight: 400;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 6px 12px;

  &.danger {
    color: $colorPrimaryBrightest;
    background-color: $colorPrimaryDarker;

    .icon {
      color: $colorPrimaryBrightest;
    }
  }

  .icon {
    margin-right: 12px;
    padding: 7px 0;
    display: flex;
    font-size: 22px;
    opacity: 0.9;
  }

  .message {
    padding: 8px 0;
  }
}
