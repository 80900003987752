@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.footerContainer {
  background-color: $colorBackgroundSecondary;
  padding-top: 40px;
  padding-bottom: 30px;

  .footer {
    padding-left: 30px;
    padding-right: 30px;

    @include bp(m) {
      padding-left: 100px;
      padding-right: 100px;
    }

    .logoContainer {
      align-items: center;
      display: flex;
    }

    .contentColumns {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      @include bp(m) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
      }

      .column {
        display: flex;
        flex-direction: column;

        .title {
          font-family: $fontWorkSans;
          font-weight: 500;
          font-size: 16px;
          line-height: 35px;
          color: $colorBlack;
          margin-top: 20px;

          @include bp(m) {
            margin-top: 0;
            margin-bottom: 8px;
          }
        }

        .emptyLink, a {
          font-family: $fontWorkSans;
          color: $colorBlack;
          text-decoration: none;
          font-size: 14px;
          line-height: 35px;
        }
      }
    }

    .copyright {
      font-family: $fontWorkSans;
      font-size: 14px;
      color: $colorBlack;
      line-height: 35px;
      margin-top: 40px;
    }
  }
}