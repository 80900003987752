@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.loginContainer {
  width: 260px;

  @include bp(xs) {
    width: 300px;
  }

  @include bp(s) {
    width: 372px;
  }

  .input {
    margin-top: 16px;

    input {
      border-radius: 4px;
    }
  }

  .button {
    margin-top: 30px;
  }

  .footer {
    font-family: $fontWorkSans;
    font-size: 14px;
    line-height: 16px;
    color: $colorText;
    text-align: center;
    margin-top: 20px;

    .action {
      margin-left: 6px;
      cursor: pointer;
      color: $colorPrimary;
      text-decoration: underline;
    }
  }
}