@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.button {
  border: 0;
  background-color: $colorPrimary;
  color: $colorTextSecondary;
  font-family: $fontWorkSans;
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 8px;
  cursor: pointer;

  .buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;

    .loaderIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin-right: 10px;
    }
  }

  &:hover{
    background-color: $colorPrimaryDarker;
  }

  &.fullWidth {
    width: 100%;
  }

  &.secondary {
    background-color: $colorBackgroundSecondary;
    color: $colorPrimary;

    &:hover {
      background-color: $colorBackgroundSecondaryDarker;
    }
  }

  &.white {
    background-color: $colorBackgroundWhite;
    color: $colorPrimary;

    &:hover {
      background-color: $colorBackgroundSecondary;
    }
  }

  &.disabled {
    background-color: $colorBackgroundSecondaryDarker;
    color: $colorBackgroundDisabled;
    cursor: not-allowed;
  }
}
