@import "../../styles/abstracts/variables";

.layout {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 100vh;

  &.isModalOpen {
    //position: fixed;
    overflow: hidden;
  }
}
